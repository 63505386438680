import * as React from "react"
import {Link} from 'gatsby';
import styled from "styled-components"

const Button = ({ title, external, mb = 0, mt = 0, linkTo, className }) => {
  const ButtonComponent = external ? ExternalLink : LinkButton

  const targetProps = {}
  if(external) {
    targetProps.href = linkTo
    targetProps.target = '_blank'
  } else {
    targetProps.to = linkTo
  }

  return (
    <ButtonComponent
      className={className}
      mb={mb}
      mt={mt}
      {...targetProps}
    >
      {title}
    </ButtonComponent>
  )
}

export default Button


const LinkButton = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 32px;
  border: none;
  border-radius: 2px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  z-index: 50;
  background: #B0ECFB;  
  box-shadow: 0 20px 30px rgba(42, 56, 94, 0.1);
  margin-bottom: ${props => props.mb ? props.mb : 0}px;
  margin-top: ${props => props.mt ? props.mt : 0}px;
`

const ExternalLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 32px;
  border: none;
  border-radius: 2px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  z-index: 50;
  background: #B0ECFB;  
  box-shadow: 0 20px 30px rgba(42, 56, 94, 0.1);
  margin-bottom: ${props => props.mb ? props.mb : 0}px;
  margin-top: ${props => props.mt ? props.mt : 0}px;
`

export const Buttons = styled.div`
  width: 100%;
  margin-bottom: ${props => props.mb ? props.mb/2 : 0}px;
  margin-top: ${props => props.mt ? props.mt/2 : 0}px;
  flex-direction: column;
  
  a {
    margin-bottom: 20px;
  }
  
  a:last-child {
    margin-bottom: 0;
  }
  
  @media only screen and (min-width: 768px) {
    margin-bottom: ${props => props.mb ? props.mb : 0}px;
    margin-top: ${props => props.mt ? props.mt : 0}px;
    flex-direction: row;
  
    a {
      margin-bottom: 0;
      margin-right: 30px;
    }
    
    a:last-child {
      margin-right: 0;
    }
  }
`
