import * as React from "react"
import { Helmet } from "react-helmet"

function Seo({ meta, lang, title, pagePath, site, isArticle }) {
  const metaDescription = meta?.metaDescription || site.globals.defaultSeo.metaDescription
  const metaTitle = meta?.metaTitle || site.globals.defaultSeo.metaTitle
  const metaImage = meta?.metaImage?.data?.attributes.url || process.env.GATSBY_SITE_URL + '/og-image.jpg'
  const metaType = isArticle ? 'article' : 'website'
  const pageUrl = process.env.GATSBY_SITE_URL.replace(/\/$/, '') + pagePath.replace('index.html', '')

  const metaTags = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: metaTitle,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: metaType,
    },
    {
      property: 'og:url',
      content: pageUrl,
    },
    {
      property: 'og:image',
      content: metaImage,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:title',
      content: metaTitle,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ]

  if(process.env.STAGING || meta?.preventIndexing) {
    metaTags.push({
      name: 'robots',
      content: 'noindex,nofollow',
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? title : metaTitle}
      titleTemplate={title ? '%s | Paywerk' : null}
      meta={metaTags}
    >
      <link rel="canonical" href={pageUrl} />
    </Helmet>
  )
}

export default Seo
