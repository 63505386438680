import { createContext } from "react"

const HeaderMenusContext = createContext({
  languageSelectorOpen: false,
  toggleLanguageOpen: () => {},
  loginMenuOpen: false,
  toggleLoginOpen: () => {},
  mobileMenuOpen: false,
  toggleMobileMenuOpen: () => {},
});

export default HeaderMenusContext