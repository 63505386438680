import * as React from "react"
import styled from "styled-components"
import Logo from "../images/logo_white.svg"
import {Link} from 'gatsby';
import ReactMarkdown from "react-markdown"
import { ArticleContent } from "./contentComponents/text"

const Footer = (props) => {
  const { site } = props

  return (
    <FooterContainer>
      <Content>
        <LogoLink to={site.pages.homepage.defaultPath}><img src={Logo} alt="" /></LogoLink>
        <FooterContentRow>
          <ContactInfo>
            <ArticleContent white={true}>
              <ReactMarkdown>{site.globals.footer.contactInfo}</ReactMarkdown>
            </ArticleContent>
          </ContactInfo>
          <FooterMenu>
            {/*<FooterLinkItem>*/}
            {/*  <FooterLinkExternal href={"/docs/" + language + "/REPLACE_FILE_NAME.pdf"} target="_blank">{site.globals.footer.menu.terms}</FooterLinkExternal>*/}
            {/*</FooterLinkItem>*/}
            {/*<FooterLinkItem>*/}
            {/*  <FooterLinkExternal href={"/docs/" + language + "/REPLACE_FILE_NAME.pdf"} target="_blank">{site.globals.footer.menu.privacy}</FooterLinkExternal>*/}
            {/*</FooterLinkItem>*/}
            {/*<FooterLinkItem>*/}
            {/*  <FooterLinkExternal href={"/docs/" + language + "/REPLACE_FILE_NAME.pdf"} target="_blank">{site.globals.footer.menu.shopper_service}</FooterLinkExternal>*/}
            {/*</FooterLinkItem>*/}
            {/*<FooterLinkItem>*/}
            {/*  <FooterLinkExternal href={"/docs/" + language + "/REPLACE_FILE_NAME.pdf"} target="_blank">{site.globals.footer.menu.merchant_service}</FooterLinkExternal>*/}
            {/*</FooterLinkItem>*/}
            {/*<FooterLinkItem>*/}
            {/*  <FooterLinkExternal href={"/docs/" + language + "/REPLACE_FILE_NAME.pdf"} target="_blank">{site.globals.footer.menu.faq}</FooterLinkExternal>*/}
            {/*</FooterLinkItem>*/}
            <FooterLinkItem>
              <FooterLink to={site.pages.contact.defaultPath}>{site.globals.footer.menu.contact}</FooterLink>
            </FooterLinkItem>
          </FooterMenu>
        </FooterContentRow>

      </Content>
      {/*<Disclaimer>*/}
      {/*  {site.globals.footer.disclaimer}*/}
      {/*</Disclaimer>*/}
    </FooterContainer>
  )
}

export default Footer


const FooterContainer = styled.footer`
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  padding: 40px 24px;
  
  @media only screen and (min-width: 1024px) {
    padding: 40px 0;
  }
`
const Content = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

const FooterContentRow = styled.div`
  position:relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 24px 0;
`

const ContactInfo = styled.div`
  width: 470px;
  flex: 0 0 auto;
`

const Disclaimer = styled.p`
  width: 100%;
  max-width: 1000px;
  margin: 40px auto;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
const LogoLink = styled(Link)`
  display: block;
  margin-right: 40px;
  
  img {
    display: block;
  }
`
const FooterMenu = styled.ul `
  column-count: 1;
  max-width: 100%;
  padding: 0;
  @media only screen and (min-width: 800px) {
    column-count: 2;
    max-width: 50%;
  }
`
const FooterLinkItem = styled.li `
  list-style: none;
  padding: 0;
  margin: 0 0 16px 0;
`
const FooterLink = styled(Link)`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
  text-decoration: none;
`

const FooterLinkExternal = styled.a`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
  text-decoration: none;
`
