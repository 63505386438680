import * as React from "react"
import {navigate} from "gatsby"

import HeaderMenusContext from "../contexts/headerMenusContext"
import Header from "./header"
import Footer from "./footer"
import styled from "styled-components"
import "../styles/styles.scss"
import vhCheck from "vh-check"
import browserLang from 'browser-lang';
import { P } from "./contentComponents/text"
import ScrollMotion from "../utils/ScrollMotion"
// import { CookieBanner } from "@palmabit/react-cookie-law";

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {languageOpen: false, loginOpen: false, mobileMenuOpen: false, cookies_confirmed: false}
    this.animations = new ScrollMotion('.motion', true)
  }

  componentDidMount() {
    vhCheck()
    this.animations.init()

    if(window.location.href.endsWith('index.html')) navigate(window.location.pathname.replace(/(index.html$)/, '')) //window.history.pushState('', document.title, window.location.href.replace(/(index.html$)/, ''))

    const {site, language, pagePath} = this.props

    const preferredLanguage = browserLang({
      languages: site.languages,
      fallback: 'en',
    });

    if(pagePath === '/') {
      document.cookie = "LANG=" + preferredLanguage + ";path=/"
      navigate('/' + preferredLanguage + '/')
    } else {
      document.cookie = "LANG=" + language + ";path=/"
    }

    const consentCookie = document.cookie.match('(^|;)\\s*confirm_privacy\\s*=\\s*([^;]+)')?.pop() || ''
    this.setState({cookies_confirmed: !!consentCookie})
  }

  componentWillUnmount() {
    this.animations.destroy()
  }

  render () {
    const {globals} = this.props.site

    return (
      <div className="layout">
        <HeaderMenusContext.Provider
          value={{
            languageSelectorOpen: this.state.languageOpen,
            toggleLanguageOpen:  () => {
              this.setState({languageOpen: !this.state.languageOpen})
            },
            loginMenuOpen: this.state.loginOpen,
            toggleLoginOpen: () => {
              this.setState({loginOpen: !this.state.loginOpen})
            },
            mobileMenuOpen: this.state.mobileMenuOpen,
            toggleMobileMenuOpen: () => {
              this.setState({mobileMenuOpen: !this.state.mobileMenuOpen})
            },
          }}
        >
          <CookieBar show={!this.state.cookies_confirmed}>
            <P className="container">{globals.cookie_consent.body} <InlineLink href={"/docs/en/privacy_notice_001_b2c3b9b8.pdf"} target="_blank">{globals.cookie_consent.privacyLinkTitle}</InlineLink>
            </P>
            <CookieButton onClick={() => {
              this.setState({cookies_confirmed: true})
              document.cookie = "confirm_privacy=yes;path=/"
            }}>{globals.cookie_consent.confirmationButtonTitle}</CookieButton>
          </CookieBar>
          {/*<CookieBanner*/}
          {/*  message={globals.cookie_consent.body}*/}
          {/*  wholeDomain={true}*/}
          {/*  policyLink={"/docs/en/privacy_notice_001_b2c3b9b8.pdf"}*/}
          {/*  privacyPolicyLinkText={globals.cookie_consent.privacyLinkTitle}*/}
          {/*  onAccept={() => {}}*/}
          {/*  onAcceptPreferences={() => {}}*/}
          {/*  onAcceptStatistics={() => {}}*/}
          {/*  onAcceptMarketing={() => {}}*/}
          {/*/>*/}
          <Light1 id="light1" />
          <Light2 id="light2" />
          <Header site={this.props.site} locale={this.props.language} pageName={this.props.pageName}/>
          <FullHeight>
            <Main>
              {this.props.children}
            </Main>
            <Footer site={this.props.site}/>
          </FullHeight>
        </HeaderMenusContext.Provider>
      </div>
    )
  }
}

export default Layout


const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 32px);
  max-width: 1000px;
  padding-top: 100px;
  padding-bottom: 48px;
  margin: 0 auto;
  flex: 1 0 auto;
  
  @media only screen and (min-width: 768px) {
    padding-bottom: 100px;
  }
  
  @media only screen and (min-width: 1000px) {
    padding-top: 150px;
    padding-bottom: 100px;
  }
`

const FullHeight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  min-height: calc(100vh - var(--vh-offset, 0px));
  background: linear-gradient(164.93deg,#CED6E9 55.59%,#EAEDFE 85.53%);
  
  @media only screen and (min-width: 768px) {
    background: linear-gradient(125.27deg, #B8C2DA 6.48%, #C1C9DE 21.53%, #F8F9FB 49.62%, #FFFFFF 70.69%);
  }
`

const Light1 = styled.div`
  position: fixed;
  top: 50%;
  right: -900px;
  width: 1px;
  height: 1px;
`

const Light2 = styled.div`
  position: fixed;
  top: 20%;
  right: -900px;
  width: 1px;
  height: 1px;
`

const CookieBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 24px 40px;
  z-index: 100;
  width: 100%;
  display: ${props => props.show ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  background: rgba(227, 231, 247, 0.9);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(227, 231, 247, 0.6);
    backdrop-filter: blur(10px);
  }
  
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const InlineLink = styled.a`
  color: #000000;
`

const CookieButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 32px;
  border: none;
  border-radius: 2px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  z-index: 50;
  background: #B0ECFB;  
  box-shadow: 0 20px 30px rgba(42, 56, 94, 0.1);
  white-space: nowrap;
  margin-left: 40px;
  cursor:pointer;
  
  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 24px;
  }
`
