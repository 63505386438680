import * as React from "react"
import styled from "styled-components"
import Arrow from "../../images/arrow.svg"
import { Link } from "gatsby"
import { useContext, useRef } from "react"
import HeaderMenusContext from "../../contexts/headerMenusContext"

const LanguageSelectorDesktop = (props) => {
  const { locale, languages, to } = props
  const { languageSelectorOpen, toggleLanguageOpen } = useContext(HeaderMenusContext);
  const langSelectorRef = useRef(null);
  let open = false

  const handleLoginButtonClick = () => {
    if (!open) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    open = !open
    toggleLanguageOpen()
  }

  const handleOutsideClick = e => {
    if (!langSelectorRef.current.contains(e.target)) {
      document.removeEventListener("click", handleOutsideClick, false);
      if(open) handleLoginButtonClick();
    }
  };

  if(languages.length < 2) return null
  return (
    <Container ref={langSelectorRef}>
      <LanguageButton
        onClick={handleLoginButtonClick}
        open={languageSelectorOpen}
      >
        {locale.toUpperCase()} <ArrowIcon src={Arrow} alt="" arrowUp={languageSelectorOpen} />
      </LanguageButton>
      <LanguageMenu open={languageSelectorOpen}>
        {languages.map((lang) => {
          if(lang === locale) return null
          return (
            <LanguageMenuLink key={lang} to={to[lang]} language={lang}>
              {lang.toUpperCase()}
            </LanguageMenuLink>
          )
        })}
      </LanguageMenu>
    </Container>
  )
}

export default LanguageSelectorDesktop

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LanguageButton = styled.a`
  position: relative;
  z-index: 20;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  cursor: pointer;
  background: transparent;
  display: flex;
  flex-flow: row nowrap;
  padding: 0 8px;
  transition: background .3s ease;
  ${props => props.open ? "background: rgba(176, 236, 251, 0.6);" : ""}
  
  @media only screen and (max-width: 767px) {
    display: none;
  }
  
  &:hover, 
  &:active {
    background: rgba(176, 236, 251, 0.6);
  }
`
const ArrowIcon = styled.img `
  display: block;
  vertical-align: middle;
  margin-left: 8px;
  
  ${props => props.arrowUp ? "transform: rotate(180deg);" : ""}
`
const LanguageMenu = styled.div`
  display: block;
  opacity: ${props => props.open? 1 : 0};
  pointer-events: ${props => props.open? "all" : "none"};
  position: absolute;
  top: 56px;
  transform: translateY(${props => props.open ? "0" : "-24px"});
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
  z-index: 1;
  
  @media only screen and (min-width: 1024px) {
    top: 62px;
  }
`
const LanguageMenuLink = styled(Link)`
  display: block;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  white-space: nowrap;
  margin-bottom: 8px;
  color: #000000;
  text-decoration: none;
  padding: 0px 16px;
  text-align: center;
  cursor: pointer;
  transition: background .3s ease;
  
  &:hover, 
  &:active {
    background: rgba(176, 236, 251, 0.6);
  }
`
