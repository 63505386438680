// Scroll animations

/*
ScrollMotion maps element viewport scroll progress
(from 0 when element enters from the bottom of the viewport
to 1 when element leaves at the top of the viewport) to from and to property values.
This is a direct range mapping solution with a possible curve mapping option in the future.

 */

class ScrollMotion {
  constructor(querySelector, enableMobile){
    this.querySelector = querySelector
    this.enableMobile = !!enableMobile
    this.lastTimestamp = 0
    this.timeDelta = 0
    this.scrollHandler = this.updateElements.bind(this)
  }

  init() {
    if(!this.enableMobile && window.innerWidth < 768) {
      return
    }
    const nodes = document.querySelectorAll(this.querySelector)

    if(nodes.length === 0) return

    this.elements = []
    nodes.forEach(node => {
      this.elements.push(new ScrollMotionElement(node))
    })

    window.addEventListener('scroll', this.scrollHandler)

    // create an Observer instance
    const that = this
    this.resizeObserver = new ResizeObserver(() => {
      that.update()
    })

    // start observing a DOM node
    this.resizeObserver.observe(document.body)

    this.scrollHandler()
  }

  update() {
    this.scrollHandler()
  }

  updateElements() {
    if(this.elements.length === 0) return
    this.elements.forEach((element) => {
      element.update()
    }, this)

    requestAnimationFrame(this.animate.bind(this))
  }

  animate(timestamp) {
    this.timeDelta = timestamp - this.lastTimestamp
    this.lastTimestamp = timestamp
    this.elements.forEach(element => {
      if(element.visible) element.animate(this.timeDelta)
    })
  }

  destroy() {
    window.removeEventListener('scroll', this.scrollHandler)
    if(this.resizeObserver) this.resizeObserver.disconnect()
    this.elements = null
  }
}

export default ScrollMotion




class ScrollMotionElement {
  constructor(node) {
    this.element = node
    this.offset = {x: 0, y: 0, z: 0}

    // get and store animation properties from element dataset
    this.targets = []
    if(node.dataset.yFrom && node.dataset.yTo) {
      this.targets.push({
        property: 'y',
        element: node,
        from: parseInt(node.dataset.yFrom),
        to: parseInt(node.dataset.yTo)
      })
    }
  }

  update() {
    // if element is (partly) in viewport - set flag this.visible,
    const rect = this.element.getBoundingClientRect()
    this.visible = rect.bottom > 0 && rect.top < window.innerHeight

    // calculate element progress through viewport and save in this.progress
    const height = rect.bottom - rect.top
    const visibleHeight = window.innerHeight + height
    this.progress = rect.bottom / visibleHeight
    this.progress = 1 - Math.max(0, Math.min(this.progress, 1))
  }

  animate(timeDelta) {
    // check what properties need to be animated and apply new calculated values
    // calculate based on element progress and from and to limits

    this.targets.forEach(target => {
      this.offset[target.property] = target.from + this.progress * (target.to - target.from)
      this.element.style.transform = `translate3D(${this.offset.x}px, ${this.offset.y}px, ${this.offset.z}px)`
    }, this)


  }
}




function move (source, target, timeDelta, inertia) {
  if (timeDelta > 1000) timeDelta = 1000;
  if (inertia === null || inertia === undefined) {
    inertia = 20
  }
  let dir = 1;
  if (source > target) dir = -1;
  let move = -dir * timeDelta / (inertia * 16.66) * Math.abs(source - target);
  source = source - move;
  if (dir === 1 && source > target) source = target;
  if (dir === -1 && source < target) source = target;
  return Number(source).toFixed(3);
}