import * as React from "react"
import styled from "styled-components"
import External from "../../images/icon_external.svg"
import Login from "../../images/login.svg"
import { useContext, useEffect, useRef, useState } from "react"
import HeaderMenusContext from "../../contexts/headerMenusContext"

const LoginComponent = ({menu}) => {
  const [shopperURL, setShopperURL] = useState("")
  const [merchantURL, setMerchantURL] = useState("")
  const { loginMenuOpen, toggleLoginOpen } = useContext(HeaderMenusContext);
  const loginRef = useRef(null);
  let open = false

  const handleLoginButtonClick = () => {
    if (!open) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    open = !open
    toggleLoginOpen()
  }

  const handleOutsideClick = e => {
    if (!loginRef.current.contains(e.target)) {
      document.removeEventListener("click", handleOutsideClick, false);
      if(open) handleLoginButtonClick();
    }
  };

  useEffect(() => {
    setShopperURL("https://shopper." + window.location.host)
    setMerchantURL("https://merchant." + window.location.host)
  }, []);

  return (
    <div ref={loginRef}>
      <LoginButton
        onClick={() => {
          handleLoginButtonClick()
        }}
        open={loginMenuOpen}
      >
        {menu.menuTitle} <LoginIcon src={Login} alt="" />
      </LoginButton>
      <LoginMenu open={loginMenuOpen}>
        <LoginMenuLink href={shopperURL} target="_blank">
          {menu.loginShopper} <ExternalIcon src={External} alt="" />
        </LoginMenuLink>
        <LoginMenuLink href={merchantURL} target="_blank">
          {menu.loginMerchant} <ExternalIcon src={External} alt="" />
        </LoginMenuLink>
      </LoginMenu>
    </div>
  )
}

export default LoginComponent


const LoginButton = styled.a`
  text-indent: -9999px;
  cursor: pointer;
  background: transparent;
  display: inline-flex;
  transition: background .3s ease;
  padding: 8px;
  ${props => props.open ? "background: rgba(176, 236, 251, 0.6);" : ""}
  
  &:active {
    background: rgba(176, 236, 251, 0.6);
  }
  
  @media only screen and (min-width: 768px) {
    &:hover {
      background: rgba(176, 236, 251, 0.6);
    }
  }
  
  @media only screen and (min-width: 1024px) {
    display: block;
    text-indent: inherit;
    border-radius: 3px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 38px;
    padding: 0 8px;
  }
`
const LoginIcon = styled.img `
  display: block;
  vertical-align: middle;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`
const LoginMenu = styled.div`
  display: block;
  opacity: ${props => props.open? 1 : 0};
  pointer-events: ${props => props.open? "all" : "none"};
  position: absolute;
  top: 68px;
  right: 0;
  transform: translateY(${props => props.open ? "0" : "-24px"});
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
  
  @media only screen and (min-width: 1024px) {
    top: 80px;
  }
`
const LoginMenuLink = styled.a`
  display: block;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  white-space: nowrap;
  margin-bottom: 8px;
  color: #000000;
  text-decoration: none;
  padding: 0 16px;
  text-align: center;
  transition: background .3s ease;
  
  &:hover, 
  &:active {
    background: rgba(176, 236, 251, 0.6);
  }
`
const ExternalIcon = styled.img`
  margin-left: 15px;
  vertical-align: middle;
`
