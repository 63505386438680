import * as React from "react"
import styled from "styled-components"

export const P = ({ strong, color, white, mt, mb, small, middle, textShadow, children, addMargins, center, className }) => {
  return (
    <PElement
      className={className}
      mt={mt}
      mb={mb}
      small={small}
      middle={middle}
      textShadow={textShadow}
      strong={strong}
      white={white}
      center={center}
      addMargins={addMargins}
      color={color}
    >
      {children}
    </PElement>
  )
}

export const H2 = ({ strong, color, white, mt, mb, children, addMargins, center, className }) => {
  return (
    <H2Element
      className={className}
      mt={mt}
      mb={mb}
      strong={strong}
      white={white}
      color={color}
      center={center}
      addMargins={addMargins}
    >
      {children}
    </H2Element>
  )
}

export const H3 = ({ strong, color, white, mt, mb, children, addMargins, center, className }) => {
  return (
    <H3Element
      className={className}
      mt={mt}
      mb={mb}
      strong={strong}
      white={white}
      center={center}
      addMargins={addMargins}
      color={color}
    >
      {children}
    </H3Element>
  )
}

export const ArticleContent = ({children, white, styles}) => {
  return (
    <ArticleContentStyles white={white} styles={styles}>
      {children}
    </ArticleContentStyles>
  )
}


const ArticleContentStyles = styled.div`
  width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 165%;
  color: ${props => props.white ? '#ffffff' : '#000000'};
  
  p, ul, ol {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 165%;
    color: ${props => props.white ? '#ffffff' : '#000000'};
  }
  
  a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 165%;
    text-decoration: none;
    color: #212A8F;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  blockquote {
    display: block;
    width: 100%;
    margin-left: 0;
    border-top: 2px solid #B0ECFB;
    border-bottom: 2px solid #B0ECFB;
    
    p, ul, ol {
      font-weight: 500;
      color: #212A8F;
    }
  }
  
  ul {
    list-style: none;
    padding-inline-start: 28px;
  }
  ul > li {
    margin: 8px 0;
    position: relative;
  }
  ul > li::before {
    position: absolute;
    top: 0;
    left: -28px;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #212A8F;
    margin: 8px 4px;
    margin-right: 12px;
  }
  
  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    padding-inline-start: 36px;
  }
  ol > li {
    counter-increment: my-awesome-counter;
    margin: 8px 0;
    position: relative;
  }
  ol > li::before {
    position: absolute;
    top: 0;
    left: -36px;
    display: flex;
    justify-content: center;
    align-items: center;
    content: counter(my-awesome-counter);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B0ECFB;
    color: #000000;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 165%;
    margin-right: 12px;
  }
  
  h1 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    color: ${props => props.white ? '#ffffff' : '#000000'};
  }
  
  h2 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    color: ${props => props.white ? '#ffffff' : '#000000'};
  }
  
  h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 120%;
    color: ${props => props.white ? '#ffffff' : '#000000'};
  }
  
  h4 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: ${props => props.white ? '#ffffff' : '#000000'};
  }
  
  h5 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    color: ${props => props.white ? '#ffffff' : '#000000'};
  }
  
  h6 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    color: ${props => props.white ? '#ffffff' : '#000000'};
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 40px;
    margin-bottom: 24px;
  }
  
  img {
    display: block;
    max-width: 100%;
    margin: 48px auto;
  }
`

const H2Element = styled.h2`
  position: relative;
  font-weight: ${props => props.strong ? "600" : "400"};
  color: ${props => props.color ? props.color : (props.white ? "#ffffff" : "#222222")};
  margin: ${props => props.addMargins ? "1rem 0" : "0"};
  ${props => props.mt != null ? "margin-top: " + props.mt +"px;" : ""}
  ${props => props.mb != null ? "margin-bottom: " + props.mb +"px;" : ""}
  ${props => props.center ? "text-align: center;" : ""}
`

const H3Element = styled.h3`
  position: relative;
  font-weight: ${props => props.strong ? "600" : "400"};
  color: ${props => props.color ? props.color : (props.white ? "#ffffff" : "#222222")};
  margin: ${props => props.addMargins ? "1rem 0" : "0"};
  ${props => props.mt != null ? "margin-top: " + props.mt +"px;" : ""}
  ${props => props.mb != null ? "margin-bottom: " + props.mb +"px;" : ""}
  ${props => props.center ? "text-align: center;" : ""}
`

const PElement = styled.p`
  position: relative;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: ${props => props.strong ? "500" : "300"};
  font-size: ${props => props.small ? "14px" : (props.middle ? "16px" : "18px")};
  line-height: 140%;
  color: ${props => props.color ? props.color : (props.white ? "#ffffff" : "#222222")};
  ${ props => props.textShadow ? "text-shadow: 0 0 20px " + (props.white ? "#000000;" : "#ffffff;") : ""}
  margin: ${props => props.addMargins ? "1rem 0" : "0"};
  ${props => props.mt != null ? "margin-top: " + props.mt +"px;" : ""}
  ${props => props.mb != null ? "margin-bottom: " + props.mb +"px;" : ""}
  ${props => props.center ? "text-align: center;" : ""}
`
