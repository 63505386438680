import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "../images/logo.svg"
import LoginComponent from "./contentComponents/loginComponent"
import MobileMenuButton from "./contentComponents/mobileMenuButton"
import { useContext } from "react"
import LanguageSelectorDesktop from "./contentComponents/languageSelectorDesktop"
import HeaderMenusContext from "../contexts/headerMenusContext"

const Header = (props) => {
  const { site, locale, pageName } = props
  const { mobileMenuOpen } = useContext(HeaderMenusContext);

  const languageLinks = {}
  site.languages.forEach(language => {
    languageLinks[language] = site.localizedPages?.[language][pageName]?.defaultPath || '/' + language + '/'
  })

  return (
    <HeaderContainer>
      <HeaderContent>
        <LeftContent>
          <LogoLink to={'/' + locale + '/'}><img src={Logo} alt="" /></LogoLink>
          <DesktopMenu>
            <HeaderLink to={site.pages.invoice.defaultPath}>{site.globals.header.menu.invoice}</HeaderLink>
            <HeaderLink to={site.pages.slice.defaultPath}>{site.globals.header.menu.slice}</HeaderLink>
            <HeaderLink to={site.pages.about.defaultPath}>{site.globals.header.menu.about}</HeaderLink>
            <HeaderLink to={site.pages.financier.defaultPath}>{site.globals.header.menu.financier}</HeaderLink>
            {locale === 'en' && <HeaderLink to={site.pages.blog.defaultPath}>{site.globals.header.menu.blog}</HeaderLink>}
          </DesktopMenu>
        </LeftContent>
        <RightContent>
          <LoginComponent menu={site.globals.header.loginMenu}/>
          <LanguageSelectorDesktop locale={locale} languages={site.languages} to={languageLinks}/>
          <MobileMenuButton />
        </RightContent>
      </HeaderContent>
      <MobileMenu open={mobileMenuOpen}>
        <MobileHeaderLink open={mobileMenuOpen} delay="0s" to={site.pages.invoice.defaultPath}>{site.globals.header.menu.invoice}</MobileHeaderLink>
        <MobileHeaderLink open={mobileMenuOpen} delay="0.1s" to={site.pages.slice.defaultPath}>{site.globals.header.menu.slice}</MobileHeaderLink>
        <MobileHeaderLink open={mobileMenuOpen} delay="0.3s" to={site.pages.about.defaultPath}>{site.globals.header.menu.about}</MobileHeaderLink>
        <MobileHeaderLink open={mobileMenuOpen} delay="0.4s" to={site.pages.financier.defaultPath}>{site.globals.header.menu.financier}</MobileHeaderLink>
        {locale === 'en' && <MobileHeaderLink open={mobileMenuOpen} delay="0.4s" to={site.pages.blog.defaultPath}>{site.globals.header.menu.blog}</MobileHeaderLink>}
        <LanguageSelectorMobile>
          {site.languages.map((lang) => {
            return (
              <MobileLanguageLink key={lang} selected={lang === locale} to={languageLinks[lang]} language={lang}>
                {lang.toUpperCase()}
              </MobileLanguageLink>
            )
          })}
        </LanguageSelectorMobile>
      </MobileMenu>
    </HeaderContainer>
  )
}

export default Header


const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  padding: 0 16px;
  z-index: 100;
  height: 60px;
  background: rgba(227, 231, 247, 0.9);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(227, 231, 247, 0.2);
    backdrop-filter: blur(10px);
  }
  
  @media only screen and (min-width: 1000px) {
    padding: 0 32px;
  }
  
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
  }
`

const HeaderContent = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
  
  @media only screen and (min-width: 1024px) {
    height: 72px;
  }
`

const DesktopMenu = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  
  @media only screen and (min-width: 1024px) {
    display: flex;
  }
`

const LogoLink = styled(Link)`
  display: block;
  margin-right: 40px;
  
  img {
    display: block;
  }
`

const HeaderLink = styled(Link)`
  display: block;
  padding: 23px 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 40px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  
  &:hover, &[aria-current="page"] {
    border-bottom: 3px solid #B0ECFB;
  }
  
  @media only screen and (min-width: 1024px) {
    height: 100%;
  }
`

const MobileHeaderLink = styled(HeaderLink)`
  transform: translateY(${props => props.open ? "0" : "-100px"});
  opacity: ${props => props.open ? 1 : 0};
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition-delay: ${props => props.delay};
  margin-right: 0;
`

const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 80px;
  background: wheat;
  z-index: 99;
  background: linear-gradient(47.16deg, #C3CEE9 22.39%, #EEF0FA 84.47%);
  transform: ${props => props.open ? "translateY(0)" : "translateY(-100%)"};
  transition: transform 0.5s ease-in-out;
  
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`

const LanguageSelectorMobile = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
`

const MobileLanguageLink = styled(HeaderLink)`
  opacity: ${props => props.selected ? 1 : 0.5};
  
  &:last-of-type {
    margin-right: 0;
  }
`

const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

