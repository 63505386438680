import * as React from "react"
import styled from "styled-components"
import menuClose from "../../images/menu_close.svg"
import menuOpen from "../../images/menu_open.svg"
import { useContext } from "react"
import HeaderMenusContext from "../../contexts/headerMenusContext"

const MobileMenuButton = () => {
  const { mobileMenuOpen, toggleMobileMenuOpen } = useContext(HeaderMenusContext);

  return (
    <MenuButton
      onClick={() => {
        toggleMobileMenuOpen()
      }}
      open={mobileMenuOpen}
    >
      {!mobileMenuOpen && <Icon src={menuOpen} alt="Open menu" />}
      {mobileMenuOpen && <Icon src={menuClose} alt="Close menu" />}
    </MenuButton>
  )
}

export default MobileMenuButton


const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  cursor: pointer;
  transition: background .3s ease;
  ${props => props.open ? "background: rgba(176, 236, 251, 0.6);" : ""}
  
  &:active {
    background: rgba(176, 236, 251, 0.6);
  }
  
  @media only screen and (min-width: 768px) {
    &:hover {
      background: rgba(176, 236, 251, 0.6);
    }
  }
  
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`

const Icon = styled.img`
  width: 100%;
  height: 100%;
`
